const NoteLabelSVG = (props) => (
  <svg
    id="Group_125"
    data-name="Group 125"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_91" data-name="Path 91" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_92"
      data-name="Path 92"
      d="M6.455,19,2,22.5V4A1,1,0,0,1,3,3H21a1,1,0,0,1,1,1V18a1,1,0,0,1-1,1Zm-.692-2H20V5H4V18.385ZM11,10h2v2H11ZM7,10H9v2H7Zm8,0h2v2H15Z"
      fill="#222"
    />
  </svg>
);
export default NoteLabelSVG;
