import axios from "../../axios";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useNavigate } from "react-router-dom";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import Logo from "../../assets/svgs/logo.svg";

import styles from "./Home.module.scss";

const cloudinaryBaseUrl = "https://res.cloudinary.com/restuware/image/upload/";

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);

const HomePage = (props) => {
  const location = useLocation();

  const getCategory = () => {
    var search = location.search.substring(1);
    if (!search) return "hostess";
    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    ).category;
  };

  const imgWrprRef = useRef();
  const [packages, setPackages] = useState([]);
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedCategory, setSelectedCategory] = useState(getCategory());
  const navigate = useNavigate();

  const getPackages = async () => {
    console.log("GETTING PACKAGE");
    let result = await axios.get(`/package/?type=${selectedCategory}`);
    setPackages(result.data);
  };

  // const createOrder = async () => {
  //   let order = await axios.post(`/user-package/${selectedPackage}`);

  //   navigate(`/package/${order.data._id}/1`);
  // };

  const createOrder = async () => {
    let order = await axios.post(`/order/${selectedPackage}`);

    navigate(`/order/${order.data._id}/1`);
  };

  const setOrientation = () => setScreenWidth(window.innerWidth);

  const setCategory = (val) => {
    navigate(`/?category=${val}`);
    setSelectedCategory(val);
  };

  useEffect(() => {
    getPackages();
    setOrientation();
    window.addEventListener("resize", setOrientation);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line
  }, [selectedCategory]);
  //   console.log(navigate);
  return (
    <div className={styles.home}>
      <div className={`df acc ${styles.topRibbon}`}>
        <div className={`df ${styles.innerWrpr}`}>
          <p
            data-text="Hostess Packages"
            className={`${
              selectedCategory === "hostess" ? "ffmsb" : "ffml"
            } fs20`}
            onClick={() => setCategory("hostess")}
          >
            Hostess Packages
          </p>
          {/* <p
            data-text="Mishloach Manos"
            className={`${selectedCategory === "mm" ? "ffmsb" : "ffml"} fs20`}
            onClick={() => setCategory("mm")}
          >
            Mishloach Manos
          </p> */}
          <p
            data-text="Upsherin"
            className={`${selectedCategory === "up" ? "ffmsb" : "ffml"} fs20`}
            onClick={() => setCategory("up")}
          >
            Upsherin
          </p>
        </div>
        {/* <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={3}
          //   freeMode={true}
          watchSlidesProgress={true}
          className={styles.thumbSlider}
          initialSlide={1}
          centeredSlides={true}
        >
          {packages.map((pack, i) => (
            <SwiperSlide
              className={`ffml fs20 thumbSlide ${styles.thumbSlide}`}
            >
              {pack.name}
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
      {packages.length ? (
        <div className={styles.swiperWrpr}>
          {/* <Swiper direction={"vertical"} className={styles.mainSlider}> */}
          <Swiper
            direction={screenWidth <= 600 ? "vertical" : "horizontal"}
            slidesPerView={"auto"}
            loop={false}
            spaceBetween={30}
            // navigation={true}
            // thumbs={{ swiper: thumbsSwiper }}
            className={styles.mainSlider}
            centeredSlides={true}
            initialSlide={1}
            // watchSlidesProgress={true}
            slideToClickedSlide={true}
          >
            {packages.map((pack, idx) => (
              //    style={{ width: "186px" }}
              <SwiperSlide
                key={pack._id}
                className={`df ffc aic ${styles.slide}`}
                // style={{ width: "186px", height: "300px" }}
                onClick={() => setSelectedPackage(pack._id)}
              >
                <div className={styles.imgWrpr} ref={imgWrprRef}>
                  <img
                    src={`${cloudinaryBaseUrl}c_scale,h_550,w_550/${pack.closedImageUrl}`}
                    alt={pack.name}
                  />
                  {/* ) : undefined} */}
                  {/* https://res.cloudinary.com/restuware/image/upload/ */}
                  {/* <IKImage
                    urlEndpoint={urlEndpoint}
                    path={pack.imageUrl}
                    alt={pack.name}
                    transformation={[
                      {
                        height: imgWrprRef?.current?.clientHeight,
                        width: imgWrprRef?.current?.clientWidth,
                      },
                    ]}
                  /> */}
                </div>
                <p className={`ffaff fs36 btext tac ttcaps ${styles.packName}`}>
                  {pack.name}
                </p>
                <p className={`ffmm fs20`} style={{ lineHeight: "24px" }}>
                  From ${pack.startingPrice?.toFixed(2)}
                </p>

                <div
                  className={`df acc ${styles.checkMarkWrpr} ${
                    selectedPackage === pack._id ? styles.selectedPack : ""
                  }`}
                >
                  {selectedPackage === pack._id ? (
                    <svg
                      className={styles.plusSvg}
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.243"
                      height="15.076"
                      viewBox="0 0 21.243 15.076"
                    >
                      <path
                        id="Path_73"
                        data-name="Path 73"
                        d="M11.6,17.508,23.108,5.979l1.771,1.773L11.6,21.055,3.636,13.074,5.406,11.3Z"
                        transform="translate(-3.636 -5.979)"
                        fill="#fff"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="Group_86"
                      data-name="Group 86"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <path
                        id="Path_74"
                        data-name="Path 74"
                        d="M0,0H30V30H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_75"
                        data-name="Path 75"
                        d="M12.5,12.5V5H15v7.5h7.5V15H15v7.5H12.5V15H5V12.5Z"
                        transform="translate(1.25 1.25)"
                      />
                    </svg>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        "Coming soon..."
      )}

      <div className={`df acc ${styles.nextWrpr}`}>
        <button
          className={`df acc ${styles.nextBtn}`}
          disabled={!selectedPackage}
          onClick={createOrder}
        >
          <span className={`ffmsb fs16 ttuc`}>Next</span>{" "}
          <span className={`df acc mla ${styles.icon}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.045"
              height="21.045"
              viewBox="0 0 21.045 21.045"
            >
              <g
                id="Group_90"
                data-name="Group 90"
                transform="translate(-1025 -1011)"
              >
                <path
                  id="Path_76"
                  data-name="Path 76"
                  d="M0,0H21.045V21.045H0Z"
                  transform="translate(1025 1011)"
                  fill="none"
                />
                <path
                  id="Path_77"
                  data-name="Path 77"
                  d="M14.673,10.165l-4.7-4.7,1.24-1.24,6.82,6.82-6.82,6.82-1.24-1.24,4.7-4.7H4V10.165Z"
                  transform="translate(1024.507 1010.286)"
                  fill="#fff"
                />
              </g>
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default HomePage;
