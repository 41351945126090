import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../axios";

import PremiumSvg from "../../assets/svgs/premium.svg";
import MinusButtonSVG from "../../assets/svgs/minus.svg";
import PlusButtonSVG from "../../assets/svgs/plus.svg";

import styles from "./Cart.module.scss";

const cloudinaryBaseUrl = "https://res.cloudinary.com/restuware/image/upload/";

const CartPage = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [thisPackage, setThisPackage] = useState(undefined);
  const [count, setCount] = useState(order?.quantity);
  const [checkoutDisabled, setCheckoutDisabled] = useState(false);

  const [countDisabled, setCountDisabled] = useState(false);

  const getData = async () => {
    let order = await axios.get(`/order/${params.orderid}`);

    setOrder(order.data);
    setCount(order.data.quantity);
    let localPackage = await axios.get(`/package/${order.data.packageId._id}`);

    setThisPackage(localPackage.data);
  };

  const getItem = (itmId, itmVariant) => {
    let localItem = thisPackage.items.find((itm) => {
      return itm._id === itmId;
    });

    let item = localItem.variants.find((variant) => variant._id === itmVariant);
    return item;
  };

  const getTotalCost = () => {
    let premiums = order.selectedItems.reduce((acc, curr) => {
      // console.log(curr.itemId);
      let localItem = thisPackage.items.find(
        (item) => item._id === curr.itemId
      );

      let localVariant = localItem.variants.find(
        (variant) => variant._id === curr.variant
      );

      acc += localVariant.price;
      return acc;
    }, 0);

    return Number(thisPackage.startingPrice + premiums).toFixed(2);
  };

  const updateOrder = async (data) => {
    setCountDisabled(true);
    let d = await axios.put(`/order/${params.orderid}`, data);
    setOrder(d.data);
    setCountDisabled(false);
  };

  const goToCheckout = async () => {
    setCheckoutDisabled(true);
    await axios.put(`/order/${params.orderid}/?set-price=true`);
    navigate(`/checkout/${params.orderid}`);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateOrder({ quantity: count });
    // eslint-disable-next-line
  }, [count]);

  return (
    <div className={`df ffc ${styles.cartPage}`}>
      <p className={`ffml fs20 p-t-50`}>My Cart</p>
      {thisPackage ? (
        <>
          <div className={`df m-t-25 ${styles.itemDisplay}`}>
            <div className={`df ${styles.itemWrpr}`}>
              {/* <div className={styles.imgWrpr}>
                <>

                  <img
                    src={`${cloudinaryBaseUrl}c_scale,h_150,w_150/${thisPackage.imageUrl}`}
                    alt={thisPackage.name}
                  />

                  {order?.selectedItems?.map((itm) => (
                    <img
                      src={`${cloudinaryBaseUrl}c_scale,h_150,w_150/${
                        getItem(itm.itemId, itm.variant).imageUrl
                      }`}
                      alt={thisPackage.name}
                      style={{ zIndex: itm.zIndex }}
                    />
                  ))}
                </>
              </div> */}

              <div className={styles.imgSlideWrpr}>
                <div className={styles.imgSlidesInnerWrpr}>
                  <div className={`${styles.front} ${styles.imgWrpr}`}>
                    <img
                      src={`${cloudinaryBaseUrl}c_scale,h_150,w_150/${thisPackage.closedImageUrl}`}
                      alt={thisPackage.name}
                    />
                  </div>

                  <div className={`${styles.imgWrpr} ${styles.back}`}>
                    <>
                      <img
                        src={`${cloudinaryBaseUrl}c_scale,h_150,w_150/${thisPackage.imageUrl}`}
                        alt={thisPackage.name}
                      />

                      {order?.selectedItems?.map((itm) => (
                        <img
                          src={`${cloudinaryBaseUrl}c_scale,h_150,w_150/${
                            getItem(itm.itemId, itm.variant).imageUrl
                          }`}
                          alt={thisPackage.name}
                          style={{ zIndex: itm.zIndex }}
                        />
                      ))}
                    </>
                  </div>
                </div>
              </div>

              <div className={`m-l-30 ${styles.itemsList}`}>
                <p className={`ffaff fs30 ttcaps m-b-10`}>
                  {order.packageId.name}
                </p>

                {order?.selectedItems?.map((itm) => (
                  <p
                    key={getItem(itm.itemId, itm.variant)._id}
                    className={`ffmr fs16 ttcaps ${styles.itemLine}`}
                  >
                    {getItem(itm.itemId, itm.variant).name}{" "}
                    {getItem(itm.itemId, itm.variant).price > 0 ? (
                      <PremiumSvg height="10" width="10" />
                    ) : undefined}
                  </p>
                ))}
              </div>
            </div>

            <div className={`df ffc mla ${styles.incWrpr}`}>
              <div className={`df aic ${styles.counterWrpr}`}>
                <div
                  className={`df acc ${styles.buttonWrpr} ${
                    count === 1 ? styles.disabled : ""
                  }`}
                  onClick={() =>
                    count > 1 && !countDisabled && setCount(count - 1)
                  }
                >
                  <MinusButtonSVG />
                </div>
                <p className={`ffmsb fs20 tac ${styles.count}`}>{count}</p>
                <div
                  className={`df acc ${styles.buttonWrpr} ${
                    count > 500 ? styles.disabled : ""
                  }`}
                  onClick={() => !countDisabled && setCount(count + 1)}
                >
                  <PlusButtonSVG />
                </div>
              </div>
              <p className={`tar mta ffmsb fs20 ${styles.mla}`}>
                ${getTotalCost()}
              </p>
            </div>
          </div>

          <div className={`df aic m-t-50 ${styles.promoWrpr}`}>
            <input type="text" />
            <button className={`ffmsb fs14 ttuc`}>Apply</button>
          </div>
          <div className={`fs16 ${styles.totalWrpr}`}>
            <div className={`df aic ${styles.totalLine}`}>
              <p className={`ffmr`}>Total</p>
              <p className={`ffmsb mla`}>
                ${(getTotalCost() * count).toFixed(2)}
              </p>
            </div>

            <div className={`df aic ${styles.totalLine}`}>
              <p className={`ffmr`}>Delivery Charges</p>
              <p className={`ffmsb mla`}>Free Delivery</p>
            </div>

            <div className={`df aic ${styles.totalLine}`}>
              <p className={`ffmr`}>Total Amout</p>
              <p className={`ffmsb mla`}>
                ${(getTotalCost() * count).toFixed(2)}
              </p>
            </div>
          </div>

          <div className={`df acc mta ${styles.ftr}`}>
            <div className={`df acc ${styles.nextWrpr}`}>
              <button
                className={`df acc ${styles.nextBtn}`}
                disabled={checkoutDisabled}
                onClick={goToCheckout}
              >
                <span className={`ffmsb fs16 ttuc ${styles.text}`}>
                  CHECK OUT
                </span>{" "}
                <span className={`df acc mla ${styles.icon}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.045"
                    height="21.045"
                    viewBox="0 0 21.045 21.045"
                  >
                    <g
                      id="Group_90"
                      data-name="Group 90"
                      transform="translate(-1025 -1011)"
                    >
                      <path
                        id="Path_76"
                        data-name="Path 76"
                        d="M0,0H21.045V21.045H0Z"
                        transform="translate(1025 1011)"
                        fill="none"
                      />
                      <path
                        id="Path_77"
                        data-name="Path 77"
                        d="M14.673,10.165l-4.7-4.7,1.24-1.24,6.82,6.82-6.82,6.82-1.24-1.24,4.7-4.7H4V10.165Z"
                        transform="translate(1024.507 1010.286)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </>
      ) : undefined}
    </div>
  );
};

export default CartPage;
