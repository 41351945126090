import styles from "./Footer.module.scss";

const Footer = (props) => {
  return (
    <div className={`df acc mta ffml fs16 ${styles.footer}`}>
      Copyright © 2021 Permili – All Rights Reserved.
    </div>
  );
};

export default Footer;
