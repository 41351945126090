// import { useParams, useNavigate, Outlet } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./Summary.module.scss";
import axios from "../../axios";
import { useEffect, useRef, useState } from "react";
import PremiumSvg from "../../assets/svgs/premium.svg";

const cloudinaryBaseUrl = "https://res.cloudinary.com/restuware/image/upload/";

const SummaryPage = (props) => {
  const optionImgWrpr = useRef();
  const selectionImgWrprRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [thisPackage, setThisPackage] = useState(undefined);

  const getData = async () => {
    // let order = await axios.get(`/user-package/${params.orderid}`);
    let order = await axios.get(`/order/${params.orderid}`);

    setOrder(order.data);
    let localPackage = await axios.get(`/package/${order.data.packageId._id}`);

    setThisPackage(localPackage.data);
  };

  const getTotalCost = () => {
    let premiums = order.selectedItems.reduce((acc, curr) => {
      // console.log(curr.itemId);

      let localItem = thisPackage.items.find((item) => {
        // console.log("LOINMJIO", item._id, curr.itemId);
        return item._id === curr.itemId;
      });

      let localVariant = localItem.variants.find(
        (variant) => variant._id === curr.variant
      );

      acc += localVariant.price;
      return acc;
    }, 0);

    return Number(thisPackage.startingPrice + premiums).toFixed(2);
  };

  const getImageUrl = (itmId, itmVariant) => {
    let localItem = thisPackage.items.find((itm) => {
      return itm._id === itmId;
    });

    let url = localItem.variants.find(
      (variant) => variant._id === itmVariant
    ).imageUrl;
    return url;
  };

  const getItemVariant = (itemId, itemVariant) => {
    let localItem = thisPackage.items.find((itm) => {
      return itm._id === itemId;
    });

    let item = localItem.variants.find(
      (variant) => variant._id === itemVariant
    );
    return item;
  };

  // const goToCart = () => navigate(`details`);
  const goToCart = () => navigate(`/cart/${params.orderid}`);

  useEffect(() => {
    getData();
    document.querySelector(".pageContainer").scrollTo(0, 0);
    console.log("IOUBVYUKUB ", document.querySelector(".pageContainer"));
    // eslint-disable-next-line
  }, []);

  // componentDidMount = () => {
  //   document.querySelector(".App").scrollTo(0, 0);
  // };

  // componentDidUpdate = (prevProps) => {
  //   if (this.props.location !== prevProps.location)
  //     document.querySelector(".App").scrollTo(0, 0);
  // };
  // const showThumbs = () => optionImgWrpr?.current;

  // useEffect(() => {
  //   setShowThumbsBool(optionImgWrpr?.current);
  // }, [optionImgWrpr?.current]);
  return (
    <div className={`pageContainer ${styles.pageContainer}`}>
      <div className={`df ${styles.summaryPage}`}>
        {thisPackage ? (
          <>
            <div className={`df ffc ${styles.leftSec}`}>
              <div className={`df aic ${styles.hdr}`}>
                <p className={`ffml fs20 ${styles.prompt}`}>
                  {/* Select your */}
                  {/* {thisPackage.items[params.step - 1].name} */}
                  Package Summary
                </p>
                <p className={`mla ffmm fs20`}>${getTotalCost()}</p>
              </div>
              <div className={styles.listWrpr}>
                {order.selectedItems.map((item) => (
                  <div
                    key={getItemVariant(item.itemId, item.variant)._id}
                    className={`df ffc acc ${styles.variantWrpr}`}
                    ref={optionImgWrpr}
                    // onClick={() => updateOrderItem(variant._id)}
                  >
                    {getItemVariant(item.itemId, item.variant).price > 0 ? (
                      <div className={styles.premiumWrpr}>
                        <PremiumSvg />
                      </div>
                    ) : undefined}
                    <div className={styles.imgWrpr}>
                      {/* <img
                      src={
                        getItemVariant(item.itemId, item.variant)
                          .displayImageUrl
                      }
                      alt={getItemVariant(item.itemId, item.variant).name}
                    /> */}

                      {/* <IKImage
                      urlEndpoint={urlEndpoint}
                      path={
                        getItemVariant(item.itemId, item.variant)
                          .displayImageUrl
                      }
                      transformation={[
                        {
                          height: 300,
                          width: 300,
                        },
                      ]}
                    /> */}

                      <img
                        src={`${cloudinaryBaseUrl}c_scale,h_300,w_300/${
                          getItemVariant(item.itemId, item.variant)
                            .displayImageUrl
                        }`}
                        alt={thisPackage.name}
                      />

                      {getItemVariant(item.itemId, item.variant).price > 0 ? (
                        <p className={`ffmm fs20 ${styles.premiumPrice}`}>
                          ${getItemVariant(item.itemId, item.variant).price}
                        </p>
                      ) : undefined}
                    </div>
                    <p className={`ffaff fs20 ttcaps m-t-12 m-b-12`}>
                      {getItemVariant(item.itemId, item.variant).name}
                    </p>
                  </div>
                ))}
              </div>
              <div className={`df acc mta ${styles.ftr}`}>
                <div className={`df acc ${styles.nextWrpr}`}>
                  <button
                    className={`df acc ${styles.nextBtn}`}
                    // disabled={!selectedVariant}
                    onClick={goToCart}
                  >
                    <span className={`ffmsb fs16 ttuc ${styles.btnText}`}>
                      Add to cart
                    </span>{" "}
                    <span className={`df acc mla ${styles.icon}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.045"
                        height="21.045"
                        viewBox="0 0 21.045 21.045"
                      >
                        <g
                          id="Group_90"
                          data-name="Group 90"
                          transform="translate(-1025 -1011)"
                        >
                          <path
                            id="Path_76"
                            data-name="Path 76"
                            d="M0,0H21.045V21.045H0Z"
                            transform="translate(1025 1011)"
                            fill="none"
                          />
                          <path
                            id="Path_77"
                            data-name="Path 77"
                            d="M14.673,10.165l-4.7-4.7,1.24-1.24,6.82,6.82-6.82,6.82-1.24-1.24,4.7-4.7H4V10.165Z"
                            transform="translate(1024.507 1010.286)"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {/* <div className={styles.rightSec}>
            <div className={styles.imgWrpr}>
              <img src={thisPackage.imageUrl} alt={thisPackage.name} />
              {order.selectedItems.map((itm) => (
                <img
                  key={itm.itemId}
                  alt={"Item"}
                  src={getImageUrl(itm.itemId, itm.variant)}
                />
              ))}
            </div>
          </div> */}

            <div className={`df ffc aic ${styles.rightSec}`}>
              <div className={styles.imgWrpr} ref={selectionImgWrprRef}>
                <img
                  src={`${cloudinaryBaseUrl}c_scale,h_750,w_750/${thisPackage.imageUrl}`}
                  alt={thisPackage.name}
                />
                {/* <img
                src={`https://res.cloudinary.com/restuware/image/upload/c_scale,h_750,w_750/l_permili:001:options:Mint_sel_v1,w_200/l_permili:001:options:Candles_Onpouch_sel_v1,h_750,w_750,x_-150/permili/001/0_BoxEmpty_v1.png`}
                alt={thisPackage.name}
              /> */}

                {order.selectedItems.map((itm, idx) => (
                  <img
                    src={`${cloudinaryBaseUrl}c_scale,h_750,w_750/${getImageUrl(
                      itm.itemId,
                      itm.variant
                    )}`}
                    alt={"Package"}
                    style={{ zIndex: itm.zIndex }}
                  />
                ))}
              </div>
              <p className={`ffaff fs36 tac ${styles.packageName}`}>
                {order.packageId.name}
              </p>
            </div>
            <div className={`df aic ${styles.hdr} ${styles.mobileHdr}`}>
              <p className={`ffml fs20 ${styles.prompt}`}>
                {/* Select your */}
                {/* {thisPackage.items[params.step - 1].name} */}
                Package Summary
              </p>
              <p className={`mla ffmm fs20`}>${getTotalCost()}</p>
            </div>
          </>
        ) : (
          "No package"
        )}

        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default SummaryPage;
