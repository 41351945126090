import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../axios";
import PremiumSvg from "../../assets/svgs/premium.svg";
import { BackArrowSVG } from "../../assets/svgs/back-arrow.svg";

import styles from "./Order.module.scss";

const cloudinaryBaseUrl = "https://res.cloudinary.com/restuware/image/upload/";

// console.log(useRoutes);

const OrderPage = (props) => {
  const thumbWrpr = useRef();
  const selectionImgWrprRef = useRef();
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [thisPackage, setThisPackage] = useState(undefined);
  const [selectedVariant, setSelectedVariant] = useState();
  const [openPreview, setOpenPreview] = useState(false);

  const getData = async () => {
    // setSelectedVariant(undefined);
    // let order = await axios.get(`/user-package/${params.packageid}`);

    let order = await axios.get(`/order/${params.orderid}`);

    setOrder(order.data);
    let localPackage = await axios.get(`/package/${order.data.packageId._id}`);
    // console.log(localPackage)

    let thisItemId = localPackage.data.items[params.step - 1]._id;
    let selectedItemId = order.data.selectedItems.find(
      (itm) => itm.itemId === thisItemId
    );

    if (selectedItemId) {
      setSelectedVariant(selectedItemId.variant);
    }

    setThisPackage(localPackage.data);
  };

  // const updateOrderItem = async (id) => {
  //   let localOrder = await axios.put(
  //     `/user-package/item/${params.packageid}/`,
  //     {
  //       itemId: thisPackage.items[params.step - 1]._id,
  //       variant: id,
  //       zIndex: thisPackage.items[params.step - 1].zIndex,
  //     }
  //   );

  //   setSelectedVariant(id);
  //   setOrder(localOrder.data);
  // };

  const updateOrderItem = async (id) => {
    let localOrder = await axios.put(`/order/item/${params.orderid}/`, {
      itemId: thisPackage.items[params.step - 1]._id,
      variant: id,
      zIndex: thisPackage.items[params.step - 1].zIndex,
    });

    setSelectedVariant(id);
    setOrder(localOrder.data);
  };
  const getImageUrl = (itmId, itmVariant) => {
    let localItem = thisPackage.items.find((itm) => {
      // console.log(itm._id, itmId);
      return itm._id === itmId;
    });

    let url = localItem.variants.find(
      (variant) => variant._id === itmVariant
    ).imageUrl;
    return url;
  };

  // const goNext = () => {
  //   if (params.step < thisPackage.items.length) {
  //     let step = Number(params.step) + 1;
  //     navigate(`/package/${params.packageid}/${step}`);
  //   } else {
  //     navigate(`/summary/${params.packageid}`);
  //   }
  // };

  const goNext = () => {
    if (params.step < thisPackage.items.length) {
      let step = Number(params.step) + 1;
      navigate(`/order/${params.orderid}/${step}`);
    } else {
      navigate(`/summary/${params.orderid}`);
    }
  };

  useEffect(() => {
    setSelectedVariant(undefined);
    getData();
    // eslint-disable-next-line
  }, [params.step]);

  const getTotalCost = () => {
    let premiums = order.selectedItems.reduce((acc, curr) => {
      // console.log(curr.itemId);
      let localItem = thisPackage.items.find(
        (item) => item._id === curr.itemId
      );

      let localVariant = localItem.variants.find(
        (variant) => variant._id === curr.variant
      );

      acc += localVariant.price;
      return acc;
    }, 0);

    return Number(thisPackage.startingPrice + premiums).toFixed(2);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`df ${styles.orderPage}`}>
      {thisPackage ? (
        <>
          <div className={`df ffc ${styles.leftSec}`}>
            <div className={`df aic ${styles.hdr}`}>
              <div
                className={`df acc ${styles.backBtn}`}
                onClick={() => navigate(-1)}
              >
                <BackArrowSVG />
              </div>
              <p className={`ffml fs20 ${styles.prompt}`}>
                Select your {thisPackage.items[params.step - 1].name}
              </p>
              <p className={`mla ffmm fs20`}>${getTotalCost()}</p>
            </div>
            <div className={styles.listWrpr}>
              {thisPackage.items[params.step - 1].variants.map((variant) => (
                <div
                  key={variant._id}
                  className={`df ffc acc ${styles.variantWrpr}`}
                  onClick={() => updateOrderItem(variant._id)}
                >
                  {variant.price > 0 ? (
                    <div className={styles.premiumWrpr}>
                      <PremiumSvg />
                    </div>
                  ) : undefined}
                  <div className={styles.imgWrpr} ref={thumbWrpr}>
                    {/* <IKImage
                      urlEndpoint={urlEndpoint}
                      path={`${variant.displayImageUrl}`}
                      transformation={[
                        {
                          height: 300,
                          width: 300,
                        },
                      ]}
                    /> */}

                    {thumbWrpr?.current ? (
                      <img
                        src={`${cloudinaryBaseUrl}c_scale,h_${thumbWrpr?.current?.clientHeight},w_${thumbWrpr?.current?.clientWidth}/${variant.displayImageUrl}`}
                        alt={variant.name}
                      />
                    ) : undefined}

                    {variant.price > 0 ? (
                      <p className={`ffmm fs20 ${styles.premiumPrice}`}>
                        + ${variant.price.toFixed(2)}
                      </p>
                    ) : undefined}
                  </div>
                  <p className={`ffaff fs20 ttcaps m-t-12 m-b-12`}>
                    {variant.name}
                  </p>
                  <div
                    className={`df acc ${styles.marker} ${
                      selectedVariant === variant._id ? styles.selected : ""
                    }`}
                  >
                    {selectedVariant === variant._id ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.243"
                        height="15.076"
                        viewBox="0 0 21.243 15.076"
                      >
                        <path
                          id="Path_73"
                          data-name="Path 73"
                          d="M11.6,17.508,23.108,5.979l1.771,1.773L11.6,21.055,3.636,13.074,5.406,11.3Z"
                          transform="translate(-3.636 -5.979)"
                          fill="#fff"
                        />
                      </svg>
                    ) : (
                      <svg
                        id="Group_86"
                        data-name="Group 86"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <path
                          id="Path_74"
                          data-name="Path 74"
                          d="M0,0H30V30H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_75"
                          data-name="Path 75"
                          d="M12.5,12.5V5H15v7.5h7.5V15H15v7.5H12.5V15H5V12.5Z"
                          transform="translate(1.25 1.25)"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className={`df acc mta ${styles.ftr}`}>
              <div className={`df acc ${styles.nextWrpr}`}>
                <button
                  className={`df acc ${styles.nextBtn}`}
                  disabled={!selectedVariant}
                  onClick={goNext}
                >
                  <span className={`ffmsb fs16 ttuc`}>Next</span>{" "}
                  <span className={`df acc mla ${styles.icon}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.045"
                      height="21.045"
                      viewBox="0 0 21.045 21.045"
                    >
                      <g
                        id="Group_90"
                        data-name="Group 90"
                        transform="translate(-1025 -1011)"
                      >
                        <path
                          id="Path_76"
                          data-name="Path 76"
                          d="M0,0H21.045V21.045H0Z"
                          transform="translate(1025 1011)"
                          fill="none"
                        />
                        <path
                          id="Path_77"
                          data-name="Path 77"
                          d="M14.673,10.165l-4.7-4.7,1.24-1.24,6.82,6.82-6.82,6.82-1.24-1.24,4.7-4.7H4V10.165Z"
                          transform="translate(1024.507 1010.286)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`df ffc aic ${styles.rightSec} ${
              openPreview ? styles.open : ""
            }`}
          >
            <p
              className={`ffmm fs16 ${styles.previewBtn}`}
              onClick={() => setOpenPreview(!openPreview)}
            >
              {openPreview ? "Close" : "Preview"}
            </p>
            <div className={styles.imgWrpr} ref={selectionImgWrprRef}>
              {/* <IKImage
                urlEndpoint={urlEndpoint}
                path={thisPackage.imageUrl}
                transformation={[
                  {
                    height: selectionImgWrprRef?.current?.clientHeight,
                    width: selectionImgWrprRef?.current?.clientWidth,
                  },
                ]}
              /> */}
              {selectionImgWrprRef?.current ? (
                <img
                  src={`${cloudinaryBaseUrl}c_scale,h_${selectionImgWrprRef?.current?.clientHeight},w_${selectionImgWrprRef?.current?.clientWidth}/${thisPackage.imageUrl}`}
                  alt={thisPackage.name}
                />
              ) : undefined}

              {order.selectedItems.map((itm) => (
                <>
                  {selectionImgWrprRef?.current ? (
                    <img
                      src={`${cloudinaryBaseUrl}c_scale,h_${
                        selectionImgWrprRef?.current?.clientHeight
                      },w_${
                        selectionImgWrprRef?.current?.clientWidth
                      }/${getImageUrl(itm.itemId, itm.variant)}`}
                      alt={thisPackage.name}
                      style={{ zIndex: itm.zIndex }}
                    />
                  ) : undefined}
                </>
                // <IKImage
                //   style={{ zIndex: itm.zIndex }}
                //   urlEndpoint={urlEndpoint}
                //   path={getImageUrl(itm.itemId, itm.variant)}
                //   transformation={[
                //     {
                //       height: selectionImgWrprRef?.current?.clientHeight,
                //       width: selectionImgWrprRef?.current?.clientWidth,
                //     },
                //   ]}
                // />
              ))}
            </div>
            <p className={`ffaff fs36 tac ${styles.packageName}`}>
              {order.packageId.name}
            </p>
          </div>
        </>
      ) : (
        "Nope!"
      )}
    </div>
  );
};

export default OrderPage;
