import Routes from "./routing/routes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { BrowserRouter } from "react-router-dom";
// import { useEffect } from "react";
// import axios from "./axios";

function App() {
  // const getUser = async () => {
  //   let user = localStorage.getItem("user");
  //   console.log(user);

  //   if (!user) {
  //     let r = await axios.post("user", {});
  //     localStorage.setItem("user", r.data._id);
  //   }
  //   // axios.get("user");
  // };

  // useEffect(() => {
  //   getUser();
  // }, []);

  return (
    <div className="df ffc App">
      <BrowserRouter>
        <Header />
        <Routes />
        <Footer />
      </BrowserRouter>

      {/* <div className={styles.imgWrpr}>
        {thisPackage ? (
          <>
            <img src={thisPackage.imageUrl} />
            {thisPackage.items.map((item, i) => (
              <img src={item.variants[selectedIdx].imageUrl} />
            ))}
          </>
        ) : undefined}
      </div>
      <button onClick={() => createOrder("61c4a88af6180d399f9602b4")}>
        Create order
      </button>
      <div className={styles.listWrpr}>
        {thisPackage
          ? thisPackage.items.map((item) => (
              <div>
                <p>I am an item {item.name}</p>
                {item.variants.map((variant) => (
                  <p>Variant {variant.price}</p>
                ))}
                <hr />
              </div>
            ))
          : undefined}
      </div>
      <p onClick={() => setSelectedIdx(0)}>Set to 0</p>
      <p onClick={() => setSelectedIdx(1)}>Set to 1</p> */}
    </div>
  );
}

export default App;
