import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import axios from "../../axios";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us.js";
import update from "immutability-helper";

import LocationLabelSVG from "../../assets/svgs/location-label.svg";
import NoteLabelSVG from "../../assets/svgs/note-label.svg";
import DeliveryDayLabelSVG from "../../assets/svgs/delivery-day-label.svg";
import RefreshSVG from "../../assets/svgs/refresh.svg";
import PaymentSVG from "../../assets/svgs/payment.svg";

import styles from "./Checkout.module.scss";
import { useParams, useNavigate } from "react-router-dom";

const emailRegex = new RegExp(
  /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/
);

console.log(emailRegex.test("str@gmail.com"));

const CheckoutForm = (props) => {
  // const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const params = useParams();

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event) => {
    setDisabled(true);
    event.preventDefault();

    await axios.put(`/order/${params.orderid}`, props.otherData);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // const { error } = await stripe.confirmCardPayment({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: "https://www.shmili.com",
    //   },
    // });

    const paymentResult = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          //   name: "Yusuff Faruq",
        },
      },
    });

    if (paymentResult.error) {
      return props.setError(paymentResult.error.message);
    }

    navigate(`/thank-you/${params.orderid}`);
    // console.log(paymentResult.error);
  };

  return (
    <form className={`df ffc aic ${styles.form}`} onSubmit={handleSubmit}>
      {console.log(stripe)}
      <div className={`m-b-30 ${styles.line}`}>
        <label className={`df aic ffmr fs16`}>
          <PaymentSVG />
          <span className={`m-l-5`}>Payment Information</span>
        </label>
        <div className={`df aic ${styles.stripeWrpr}`}>
          <CardElement
            options={{
              classes: { base: styles.stripContainer },
              // style: { base: { iconColor: "green", border: "1px solid red" } },
            }}
          />
        </div>
      </div>
      <div className={`df acc mta ${styles.ftr}`}>
        <div className={`df acc ${styles.nextWrpr}`}>
          <button
            className={`df acc ${styles.nextBtn}`}
            type="submit"
            disabled={!stripe || !elements || props.disabled || disabled}
          >
            <span className={`ffmsb fs16 ttuc ${styles.text}`}>
              PLACE ORDER
            </span>{" "}
            <span className={`df acc mla ${styles.icon}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.045"
                height="21.045"
                viewBox="0 0 21.045 21.045"
              >
                <g
                  id="Group_90"
                  data-name="Group 90"
                  transform="translate(-1025 -1011)"
                >
                  <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M0,0H21.045V21.045H0Z"
                    transform="translate(1025 1011)"
                    fill="none"
                  />
                  <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M14.673,10.165l-4.7-4.7,1.24-1.24,6.82,6.82-6.82,6.82-1.24-1.24,4.7-4.7H4V10.165Z"
                    transform="translate(1024.507 1010.286)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ErrorToast = (props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 2000);
  }, []);
  return (
    <>
      {show ? (
        <p className={`ffmr fs14 ${styles.errorToast}`}>{props.error}</p>
      ) : undefined}
    </>
  );
};

const CheckoutPage = (props) => {
  const params = useParams();
  const [clientSecret, setClientSecret] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [address, setAddress] = useState();
  const [addressList, setAddressList] = useState();
  const [disableSearch, setDisableSearch] = useState(false);
  const [focus, setFocus] = useState(false);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [deliveryNote, setDeliveryNote] = useState();
  const [desiredDeliveryDate, setDesiredDeliveryDate] = useState(
    Number(dayjs(new Date()).add(11, "day"))
  );

  const setErrorsAction = (error) => setErrors([...errors, error]);

  const [inputErrors, setInputErrors] = useState({});

  const getAAA = async () => {
    let result = await axios.get(
      `/order/${params.orderid}/?set-payment-intent=true`
    );
    setClientSecret(result.data);

    // let aaaaaaa = await axios.get(`/helper/address-auto-complete/address`);
  };

  const getAddressPredictions = async () => {
    let list = await axios.get(`/helper/address-auto-complete/${address}`);

    setAddressList(list.data);
    setFocus(false);
    // structured_formatting / secondary_text || main_text
  };
  useEffect(() => {
    getAAA();
    console.log("MOUNTED");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!address || disableSearch) return setAddressList(undefined);
    getAddressPredictions();
    // eslint-disable-next-line
  }, [address]);

  const validateEmail = () => {
    // const newObj2 = update(obj, {b: {$set: obj.b * 2}});
    const localObj = update(inputErrors, {
      email: { $set: !emailRegex.test(email) },
    });

    setInputErrors(localObj);
  };

  return (
    <div className={styles.checkoutPage}>
      <p className={`ffml fs20 m-t-50 m-b-30`}>Place your order</p>

      <div className={`df m-b-30 ${styles.line}`}>
        <div className={styles.inputWrpr}>
          <label className={`df aic ffmr fs16`}>
            {/* <NoteLabelSVG /> */}
            <span className={`m-l-5`}>Full Name</span>
          </label>
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className={styles.inputWrpr}>
          <label className={`df aic ffmr fs16`}>
            <span className={`m-l-5`}>Phone Number</span>
          </label>

          <Cleave
            // placeholder="Phone number"
            options={{
              blocks: [0, 3, 3, 4],
              // delimiters: ["(", ") ", "-"],
              numericOnly: true,
              // phone: true,
              delimiters: ["(", ") ", "-"],
              // phoneRegionCode: "us",
            }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className={styles.inputWrpr}>
          <label className={`df aic ffmr fs16`}>
            {/* <NoteLabelSVG /> */}
            <span className={`m-l-5`}>Email</span>
          </label>
          <input
            // placeholder="Email"
            className={inputErrors.email ? styles.hasError : "undefined"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (inputErrors.email) validateEmail();
            }}
            onBlur={validateEmail}
          />
        </div>
      </div>

      {console.log(inputErrors)}

      <div className={`df m-b-30 ${styles.line}`}>
        <div className={`p-r-0 ${styles.inputWrpr}`}>
          <label className={`df aic ffmr fs16`}>
            <LocationLabelSVG />
            <span className={`m-l-5`}>Your Delivery Address</span>
          </label>
          <input
            autoComplete="disabled"
            onFocus={() => setFocus(true)}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={`p-r-60 ${
              addressList?.length && address ? styles.active : styles.none
            }`}
          ></input>

          {disableSearch && focus ? (
            <div
              className={`df acc ${styles.refreshBtn}`}
              onClick={() => {
                getAddressPredictions();
              }}
            >
              <RefreshSVG />
            </div>
          ) : undefined}

          {addressList?.length && address ? (
            <div className={`p-b-18 p-t-10 ${styles.addresListWrpr}`}>
              {addressList.map((addrs) => {
                return (
                  <div
                    className={styles.line}
                    onClick={() => {
                      setDisableSearch(true);
                      setAddress(
                        `${addrs.structured_formatting.main_text} ${addrs.structured_formatting.secondary_text}`
                      );
                      setFocus(false);
                      setAddressList(undefined);
                    }}
                  >
                    <p className={`ffmr fs14`}>
                      {addrs.structured_formatting.main_text}
                    </p>
                    <p className={`ffmr fs12 m-t-5`}>
                      {addrs.structured_formatting.secondary_text}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : undefined}
        </div>
      </div>

      <div className={`df m-b-30 ${styles.line}`}>
        <div className={styles.inputWrpr}>
          <label className={`df aic ffmr fs16`}>
            <DeliveryDayLabelSVG />
            <span className={`m-l-5`}>Delivery Day</span>
          </label>
          <DatePicker
            selected={desiredDeliveryDate}
            minDate={Number(dayjs(new Date()).add(10, "day"))}
            onChange={(date) => setDesiredDeliveryDate(date)}
            excludeDateIntervals={[
              // Number(dayjs(new Date())),
              // Number(dayjs(new Date()).add(1, "day")),
              {
                start: Number(dayjs(new Date()).add(-1, "day")),
                end: Number(dayjs(new Date()).add(10, "day")),
              },
              // addDays(new Date(), 5),
            ]}
          />
        </div>

        <div className={styles.inputWrpr}>
          <label className={`df aic ffmr fs16`}>
            <NoteLabelSVG />
            <span className={`m-l-5`}>Delivery Note</span>
          </label>
          <input
            value={deliveryNote}
            onChange={(e) => setDeliveryNote(e.target.value)}
          />
        </div>
        {console.log(errors)}
      </div>
      {clientSecret ? (
        <Elements
          //

          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <CheckoutForm
            disabled={
              !name ||
              !phoneNumber ||
              !email ||
              !address ||
              !desiredDeliveryDate ||
              // errors.length ||
              Object.values(inputErrors).indexOf(true) > -1
            }
            clientSecret={clientSecret}
            setError={setErrorsAction}
            otherData={{
              name,
              phoneNumber,
              email,
              address,
              desiredDeliveryDate,
              deliveryNote,
            }}
          />
        </Elements>
      ) : undefined}

      <div className={styles.errorToastsWrpr}>
        {errors.map((error) => (
          <ErrorToast error={error}></ErrorToast>
        ))}
      </div>
    </div>
  );
};

export default CheckoutPage;
