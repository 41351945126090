const LocationLabelSVG = (props) => (
  <svg
    id="Group_119"
    data-name="Group 119"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_83"
      // dataName="Path 83"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <path
      id="Path_84"
      // dataName="Path 84"
      d="M12,20.9l4.95-4.95a7,7,0,1,0-9.9,0Zm0,2.828L5.636,17.364a9,9,0,1,1,12.728,0ZM12,13a2,2,0,1,0-2-2A2,2,0,0,0,12,13Zm0,2a4,4,0,1,1,4-4A4,4,0,0,1,12,15Z"
      transform="translate(0 -0.864)"
      fill="#222"
    />
  </svg>
);

export default LocationLabelSVG;
