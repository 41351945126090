const DeliveryLabelSVG = (props) => (
  <svg
    id="Group_120"
    data-name="Group 120"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path id="Path_85" data-name="Path 85" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_86"
      data-name="Path 86"
      d="M17,3h4a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3H7V1H9V3h6V1h2Zm3,8H4v8H20ZM15,5H9V7H7V5H4V9H20V5H17V7H15ZM6,13H8v2H6Zm5,0h2v2H11Zm5,0h2v2H16Z"
      fill="#222"
    />
  </svg>
);

export default DeliveryLabelSVG;
