export const BackArrowSVG = (props) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7014_2106)">
      <path
        d="M5.64342 6.82391L-0.0273438 1.24391L1.21266 0.00390625L8.03266 6.82391L1.21266 13.6439L-0.0273438 12.4039L5.64342 6.82391Z"
        fill="#222222"
      />
    </g>
    <defs>
      <clipPath id="clip0_7014_2106">
        <rect width="8" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
