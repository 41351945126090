const MinusButtonSVG = (props) => {
  return (
    <svg
      id="Group_117"
      data-name="Group 117"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_81"
        // dataName="Path 81"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Path_82"
        // dataName="Path 82"
        d="M11,11h6v2H7V11Zm1,11A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Z"
        fill="#222"
      />
    </svg>
  );
};

export default MinusButtonSVG;
