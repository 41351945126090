const PaymentSVG = (props) => (
  <svg
    id="Group_129"
    data-name="Group 129"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_97"
      // dataName="Path 97"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <path
      id="Path_98"
      // dataName="Path 98"
      d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3ZM4,5V19H20V5Zm4.5,9H14a.5.5,0,0,0,0-1H10a2.5,2.5,0,1,1,0-5h1V6h2V8h2.5v2H10a.5.5,0,0,0,0,1h4a2.5,2.5,0,0,1,0,5H13v2H11V16H8.5Z"
      fill="#222"
    />
  </svg>
);

export default PaymentSVG;
