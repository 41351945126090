// import { Routes, Route } from "react-router-dom";
// import CartPage from "../pages/cart/Cart";
// import CheckoutPage from "../pages/checkout/Checkout";
// import HomePage from "../pages/home/Home";
// import OrderPage from "../pages/order/Order";
// import CartPopup from "../pages/summary/cart-popup/CartPopup";
// import SummaryPage from "../pages/summary/Summary";
// import ThanksYou from "../pages/thank-you/ThanksYou";

// const routes = (props) => {
//   return (
//     // <BrowserRouter>
//     <Routes>
//       <Route path="/thank-you/:orderid" element={<ThanksYou />}></Route>
//       <Route path="/summary/:orderid" element={<SummaryPage />}>
//         {/* <Route path="details" element={<CartPopup />}></Route> */}
//       </Route>
//       <Route path="/cart/:orderid" element={<CartPage />}></Route>
//       <Route path="/checkout/:orderid" element={<CheckoutPage />}></Route>
//       <Route path="/package/:packageid/:step" element={<OrderPage />} />
//       <Route path="/:orderid" element={<HomePage />} />
//       <Route path="/" element={<HomePage />} />
//       {/* <Route index element={<Home />} />
//           <Route path="teams" element={<Teams />}>
//             <Route path=":teamId" element={<Team />} />
//             <Route path="new" element={<NewTeamForm />} />
//             <Route index element={<LeagueStandings />} />
//           </Route> */}
//     </Routes>
//     // </BrowserRouter>
//   );
// };

// export default routes;

import { Routes, Route } from "react-router-dom";
import CartPage from "../pages/cart/Cart";
import CheckoutPage from "../pages/checkout/Checkout";
import HomePage from "../pages/home/Home";
import OrderPage from "../pages/order/Order";
import SummaryPage from "../pages/summary/Summary";
import ThanksYou from "../pages/thank-you/ThanksYou";

const routes = (props) => {
  return (
    // <BrowserRouter>
    <Routes>
      {" "}
      <Route path="/thank-you/:orderid" element={<ThanksYou />}></Route>
      <Route path="/summary/:orderid" element={<SummaryPage />}></Route>
      <Route path="/cart/:orderid" element={<CartPage />}></Route>
      <Route path="/checkout/:orderid" element={<CheckoutPage />}></Route>
      <Route path="/order/:orderid/:step" element={<OrderPage />} />
      <Route path="/:orderid" element={<HomePage />} />
      <Route path="/" element={<HomePage />} />
      {/* <Route index element={<Home />} />
          <Route path="teams" element={<Teams />}>
            <Route path=":teamId" element={<Team />} />
            <Route path="new" element={<NewTeamForm />} />
            <Route index element={<LeagueStandings />} />
          </Route> */}
    </Routes>
    // </BrowserRouter>
  );
};

export default routes;
