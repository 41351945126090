const PremiumSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24"}
    height={props.height || "24"}
    viewBox="0 0 24 24"
  >
    <g id="Group_151" dataName="Group 151" transform="translate(-7006 -2467)">
      <rect
        id="Rectangle_11"
        dataName="Rectangle 11"
        width="20.442"
        height="4.713"
        transform="translate(7007.779 2486.287)"
        fill="#fa8c00"
      />
      <path
        id="Path_78"
        dataName="Path 78"
        d="M585.772,503.421l-6.616,10.871,6.616,4.774,6.5-4.889Z"
        transform="translate(6432.165 1963.579)"
        fill="#ff8c00"
      />
      <g
        id="Group_109"
        dataName="Group 109"
        transform="translate(7006 2471.543)"
      >
        <path
          id="Path_79"
          dataName="Path 79"
          d="M597.562,506.432l-1.807,13.762-16.047-.011Z"
          transform="translate(-573.562 -506.432)"
          fill="#eda500"
        />
        <path
          id="Path_80"
          dataName="Path 80"
          d="M575.593,506.432l1.808,13.762,16.047-.011Z"
          transform="translate(-575.593 -506.432)"
          fill="#fec400"
        />
      </g>
    </g>
  </svg>
);

export default PremiumSvg;
