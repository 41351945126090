import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Logo from "../../assets/svgs/logo.svg";

import styles from "./Header.module.scss";

const Header = (props) => {
  const history = useLocation();

  const [showSub, setShowSub] = useState(true);

  useEffect(() => {
    console.log(history.pathname);
    setShowSub(history.pathname === "/");
  }, [history.pathname]);
  return (
    <div
      className={`df ffc acc ${styles.header} ${showSub ? "" : styles.noSub}`}
    >
      <Logo />
      {showSub ? (
        <p className={`ffml fs20 btext`}>
          Find your favorite and start making your own unique package
        </p>
      ) : undefined}
    </div>
  );
};

export default Header;
